import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser } from '../../../../../utils/helpers';
import { statesOptions, timezoneOptions, statePrettyName, timezonePrettyName } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';

function formatRow(item) {
  const { id, name, address, city, password, state, zip, status, timezone, external_alley_id: externalAlleyId } = item;
  
  return {
    name,
    address: <span>{address}<br />{city}, {`${state} ${zip}`}</span>,
    password,
    externalAlleyId,
    status: status ? 'Active' : 'Inactive',
    timezone: timezonePrettyName(timezone),    
    id,
  };
}

const AdminCenters = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/centers',
        label: 'Centers',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadCenters = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/centers${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setCenters(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadCenters();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'password',
      label: 'Password',
    },
    {
      key: 'externalAlleyId',
      label: 'Alley ID',
    },
    {
      key: 'address',
      label: 'Address',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'timezone',
      label: 'Timezone',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/center/edit?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Create Center',
      href: '/app/admin/center',
    },
  ];

  const baseUrl = '/app/admin/centers';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/centers',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'city',
        label: 'City',
        type: 'text',
      },
      {
        name: 'external_alley_id',
        label: 'Alley ID',
        type: 'text',
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        options: statesOptions(),
      },
      {
        name: 'zip',
        label: 'Zip',
        type: 'text',
      },
      {
        name: 'timezone',
        label: 'Timezone',
        type: 'select',
        options: timezoneOptions(),
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            label: 'Select...',
            value: '',
          },
          {
            value: 1,
            label: 'Active',
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    ],
    submitLabel: 'Search Centers',
  };

  return (
    <AdminContainer>
      <SEO title="Centers" />
      <Heading title="Centers" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Centers" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminCenters;
